<template>
    <div class="kt-portlet">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-title kt-font-bolder">
                    {{name}}
                </span>
            </div>
            <!-- <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
                <button
                type="button"
                class="btn btn-clean btn-icon btn-sm btn-icon-md btn-circle"
                ><i class="flaticon-more"></i></button>
            </div>
            </div> -->
        </div>
        <div class="row chat-list">
            <div class="col-md-12 my-auto" v-if="showWelcome">
                <div class="kt-iconbox">
                    <div class="kt-iconbox__icon">
                        <div class="kt-iconbox__icon-bg"></div>
                        <i class="flaticon-chat-2"></i>
                    </div>
                    <div class="kt-iconbox__title">
                    <h1>Halo!</h1>
                     Selamat datang di Mandaya Chat.
                    </div>
                </div>
            </div>
            <div class="col-lg-12 text-center p-5 my-auto" style="position:absolute;" v-if="showLoading">
                <div class="spinner-grow text-info" role="status"  style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
                <h6 class="mt-3">Memuat Chat...</h6>
            </div>
            <div class="col-lg-12">
            <iframe
                class="iframe-styling iframe-chat"
                :src="group_url"
                width="100%"
                height="100%"
                onload="$('.iframe-chat').show();"
                style="display:none;"
            >
            </iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    name: { type: String }
  },
  data() {
    var vx = this;
    return {
      base_url: process.env.MIX_API_CHAT+"/group/",
      group_url: null,
      showLoading: false,
      showWelcome: true,
    };
  },
  watch: {
    name: function(val) {
      this.showWelcome = false;
      this.showLoading = true;
      this.group_url = this.base_url + val;
    }
  },
  methods: {
    setChatHeight: function() {
      $(".chat-list").css("height", window.innerHeight - 260);
    }
  },
  mounted() {
    var vx = this;
    this.setChatHeight();
    $(window).resize(function() {
      vx.setChatHeight();
    });
    var browser = document.querySelector('iframe');
    browser.mute();
  },
};
</script>

<style>
</style>
