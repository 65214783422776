<template>
      <a href="#" class="list-group-item list-group-item-action flex-column align-items-start" @click="selectChat()" :class="{'active': showActive }">
            <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1 kt-font-bolder">{{ item.name }}</h6>
                <small class="text-muted" v-if="item.lastMessage">{{ convertTimestamp(item.lastMessage.timeStamp) }}</small>
            </div>
            <div class="d-flex justify-content-between">
                <p class="mb-1 text-ellipsis" v-if="item.lastMessage" >
                    <span>{{ item.lastMessage.chatUserDTO.name }}</span>: <span class="text-muted">{{ convertMessage(item.lastMessage.msg) }}</span>
                </p>
                <p class="mb-1 text-ellipsis" v-else>
                    <span class="text-muted font-italic">Tidak ada pesan.</span>
                </p>
                <div class="d-flex justify-content-end">
                    <span class="kt-badge kt-badge--inline kt-badge--danger  kt-badge--pill max-content" v-if="showMention">@ {{convertNum(mention)}}</span>
                    <span class="kt-badge kt-badge--inline kt-badge--brand  kt-badge--pill max-content" v-if="showUnRead">{{convertNum(unReadNum)}}</span>
                </div>
            </div>
        </a>
</template>

<script>
export default {
    data() {
        return {
            showMention: false,
            showUnRead: false,
            showActive: false,
        }
    },
    props:{
        item: { type: Object },
        selected: {type: String}
    },
    methods: {
        selectChat:function(){
            this.$emit('click', this.item);
        },
        convertTimestamp: function(data) {
            var momentDate = moment(data).add(7,'hours');
            var reference = moment();
            var today = reference.clone().startOf('day');
            var yesterday = reference.clone().subtract(1, 'days').startOf('day');
            var a_week_old = reference.clone().subtract(7, 'days').startOf('day');
            if(momentDate.isSame(today, 'd')){
                return momentDate.format('HH:mm');
            } else if(momentDate.isSame(yesterday, 'd')) {
                return 'Kemarin'
            } else if (momentDate.isAfter(a_week_old)){
                return momentDate.format('dddd')
            } else {
                return momentDate.format('DD/MM/YY');
            }
        },
        convertNum: function(data) {
            return (data > 99) ? '99+' : data;
        },
        convertMessage: function(data) {
            var message = data.split(/^\[ \]\((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+\) /);
            return message[message.length - 1];
        }
    },
    watch:{
        mention: function(val) {
            this.showMention = (val > 0);
        },
        unReadNum: function(val){
            this.showUnRead = (val > 0);
        },
        selected: function(val){
            this.showActive = (val == this.item.rocketChatId);
        }
    },
    computed: {
        mention(){
            return this.item.userMentions + this.item.groupMentions;
        },
        unReadNum(){
            return this.item.unread;
        }
    },
    mounted() {
        this.showMention = (this.mention > 0);
        this.showUnRead = (this.unReadNum > 0);
        this.showActive = (this.selected == this.item.rocketChatId)
    }
}
</script>

<style>

</style>
