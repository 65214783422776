<template>
    <div>
        <base-header base-title="Chat"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="row">
                <div class="col-md-4">
                    <list-group-chat @chg="changeFrame" ></list-group-chat>
                </div>
                <div class="col-md-8">
                    <chat-frame :name="name"></chat-frame>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import ListGroupChat from "./_components/ListGroupChat";
import ChatFrame from "./_components/ChatFrame";
export default {
    components: {
        BaseHeader,
        BaseTitle,
        ListGroupChat,
        ChatFrame
    },
    data() {
        var vx = this;
        return {
            headTitle: "Diskusi (Chat)",
            name: null
        }
    },
    watch: {

    },
    methods: {
        changeFrame: function(name){
            this.name = name;
        },
    },
    created() {

    }
}
</script>
