<template>
    <div class="kt-portlet">
        <div class="kt-portlet__head p-3">
            <div class="kt-portlet__head-label w-100">
            <h3 class="kt-portlet__head-title">
                <div class="kt-input-icon kt-input-icon--left">
                <input
                    @keyup="search($event)"
                    type="text"
                    class="form-control form-control-sm input-50-radius"
                    placeholder="Search..."
                    id="generalSearch"
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><i class="la la-search"></i></span>
                </span>
                </div>
            </h3>
            </div>
            <!-- <div class="kt-portlet__head-toolbar ml-2">
                <div class="kt-portlet__head-group">
                    <div class="">
                    <button
                        type="button"
                        class="btn btn-clean btn-icon btn-sm btn-icon-md btn-circle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    ><i class="flaticon-more"></i></button>
                    <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-95px, 32px, 0px);"
                    >
                        <a
                        class="dropdown-item"
                        href="#"
                        >Action</a>
                        <a
                        class="dropdown-item"
                        href="#"
                        >Another action</a>
                        <a
                        class="dropdown-item"
                        href="#"
                        >Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a
                        class="dropdown-item"
                        href="#"
                        >Separated link</a>
                    </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="kt-section__content kt-section__content--border">
            <div class="row " v-if="showNoChat"  >
                <div class="col-lg-12 text-center p-5 my-auto" style="position:absolute;">
                    <div class="kt-iconbox">
                        <div class="kt-iconbox__icon">
                            <div class="kt-iconbox__icon-bg"></div>
                            <i class="flaticon2-chat-2"></i>
                        </div>
                        <div class="kt-iconbox__title">
                            Anda belum memiliki grup chat.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 text-center p-5 my-auto" style="position:absolute;" v-if="showLoading" >
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
                <h6 class="mt-3">Memuat Chat Group...</h6>
            </div>
            <div class="list-group ps-scroll1 kt-scroll chat-list" data-scroll="true">
               <detail-group-chat  v-for="item in viewListGroup" :selected="selectedChat" :item="item" :key="item.rocketChatId" @click="selectIframe"></detail-group-chat>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ChatRepository = RepositoryFactory.get("chat");
import DetailGroupChat from "./DetailGroupChat";
import { mapState } from 'vuex';
export default {
    components:{
        DetailGroupChat
    },
    data() {
        var vx = this;
        return {
            viewListGroup: [],
            selectedChat:null,
            showLoading: true,
            showNoChat: false,
        }
    },
    computed: {
      ...mapState('ChatStore',{
          listChatGroup: state => state.listGroupChat
      })
    },
    methods: {
        setChatHeight: function() {
            $(".chat-list").css("height", window.innerHeight - 260);
        },
        selectIframe: function(item){
            this.selectedChat = item.rocketChatId;
            this.$emit('chg',item.name);
        },
        search: function(event){
            if(event.target.value !== ''){
                const filter = this.listChatGroup.filter(x => x.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
                 this.viewListGroup = filter;
            }else {
                this.viewListGroup = this.listChatGroup;
            }
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    watch: {
        listChatGroup: function(val){
            this.viewListGroup = val;
            this.showLoading = false;
            this.showNoChat = (this.viewListGroup.length == 0);
        }
    },
    created() {
        this.$store.dispatch('ChatStore/GET_LIST_CHAT_GROUP', rtrc);
    },
    mounted() {
        var vx = this;
        this.setChatHeight();
        $(window).resize(function() {
            vx.setChatHeight();
        });
        const ps1 = new PerfectScrollbar(".ps-scroll1");
    }
}
</script>

<style>

</style>
